const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5e8f1e1e161766069ca55f46',
    enableExperimentalBlocks: false,
    authMode: ['local'],
    authLocalOptions: {
      mode: 'otp',
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Radio DEMO',
      colorPrimary: '#7ab4af',
    },
    analytics: {
      matomo: '4',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '6036c4ee3052ac63142f0ff5',
            i18n: {
              fr: {
                title: 'Première collection',
                description: 'Description de la collection',
              },
            },
            heading: {
              canRadio: false,
              color: '#7AA2C2',
              dark: true,
              hideTitle: false,
              buttonColor: 'white',
              buttonLight: true,
            },
            filters: {
              type: 'solo',
              soloTagId: '6536401791951e004d9453cf',
            },
          },
          {
            type: 'tag',
            tagId: '6036c4f84c1a18b73749b0e4',
            i18n: {
              fr: {
                title: 'Deuxième collection',
                description: 'Description de la collection',
              },
            },
            heading: {
              canRadio: false,
              color: '#7AC293',
              dark: true,
              hideTitle: false,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            name: 'collection:troisième',
            type: 'tag',
            tagId: '650af4d707b99e608c0f5987',
            i18n: {
              fr: {
                title: 'Troisième collection',
                description: 'Description de la collection',
              },
            },
            heading: {
              canRadio: false,
              color: '#FED983',
              dark: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/cover-univers1.png')
                  : require('~/assets/images/cover-univers1.png')
              },
              thumbnailImage() {
                return require('~/assets/images/univers2.png')
              },
            },
          },
        ],
      },
    ],
    drawerNavigation: () => [
      {
        type: 'method',
        icon: 'mdi-message-processing',
        value: '/settings',
        title: 'Campagne SMS',
        onClick: (context) => {
          context.formModal = true
        },
      },
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],
    WFeedBack: {
      // ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: 'Enregistrez votre dédicace',
        name: 'Dédicaces audio',
        type: 'audio',
        maxDuration: 15,
        headCaption: `Laissez votre dédicace ! Elle sera diffusée à l'antenne demain entre 12h et 13h`,
        subCaption: `Je suis informé que ce site traite les données recueillies pour permettre la diffusion de votre dédicace. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message a bien été envoyé.`,
        pseudonym: {
          label: 'Nom ou pseudo (facultatif)',
          placeholder: 'Mimie Mathy',
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'mimie@mathy.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: 'Tu préfères',
            items: ['Manger', 'Boire', 'Dormir'],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label: 'Pour ou contre',
            value: false,
            type: 'switch',
          },
          {
            label: "J'accepte que mon message soit diffusé dans Radio DEMO",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
      secondFeedback: {
        title: 'Inscription aux notifications par SMS',
        name: 'Inscription SMS',
        type: 'form',
        headCaption: `Inscrivez-vous pour recevoir des notifications SMS à chaque publication.`,
        subCaption: `Je suis informé que ce site traite les données recueillies pour permettre de vous envoyer des notifications par SMS. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre inscription a bien été enregistrée.`,
        pseudonym: {
          label: 'Nom ou pseudo (facultatif)',
          placeholder: 'Mimie Mathy',
        },
        recorderForm: [
          {
            label: 'Entité',
            items: ['Entreprise 1', 'Entreprise 2', 'Entreprise 3'],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label: "J'accepte que mon message soit diffusé dans Radio DEMO",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
